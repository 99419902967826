import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { CustomersComponent } from './master/customers/customers.component';
import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { NotificatonComponent } from './notificaton/notificaton.component';
import { CutomerSearchComponent } from './cutomer-search/cutomer-search.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { InvalidLicenceComponent } from './invalid-licence/invalid-licence.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotNewPasswordComponent } from './forgot-new-password/forgot-new-password.component';
import { AddBankComponent } from './master/add-bank/add-bank.component';

const routes: Routes = [
  { path: 'add-bank', component: AddBankComponent },
  { path: 'invalid-licence', component: InvalidLicenceComponent },
  { path: 'cutomer-search', component: CutomerSearchComponent },
  { path: 'add-customer', component: CustomersComponent },
  { path: 'customer-details', component: CustomerDetailsComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'search_customer', component: SearchCustomerComponent },
  { path: 'notification', component: NotificatonComponent },
  { path: 'sales-dashboard', component: SalesDashboardComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'forgot-new-password', component: ForgotNewPasswordComponent },
  { path: '', component: LoginComponent },
  {
    path: 'authentication', component: AuthenticationComponent,
    children: [
      { path: 'login', pathMatch: 'full', data: { breadcrumb: 'Login' }, component: LoginComponent },
      { path: 'register', data: { breadcrumb: 'Register' }, component: RegisterComponent },
      { path: 'forgot-password', data: { breadcrumb: 'Forgot Password' }, component: ForgotPasswordComponent },
      { path: 'reset-password', data: { breadcrumb: 'Reset Password' }, component: ResetPasswordComponent },
    ]
  },

  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'master', loadChildren: () => import('./master/master.module').then(m => m.MasterModule) },
  { path: 'utilities', loadChildren: () => import('./utilities/utilities.module').then(m => m.UtilitiesModule) },
  { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule) },
  { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
